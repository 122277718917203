@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  .scrollbarLG::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #1e262c;
  }

  .scrollbarLG::-webkit-scrollbar-track {
    background: transparent;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #6dc032;
    border-radius: 5px;
    border: 1px solid #1e262c;
  }

  .scrollbarLG::-webkit-scrollbar-thumb {
    background: #6dc032;
    border: 1px solid #1e262c;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #03a344;
  }

  .scrollbarLG::-webkit-scrollbar-thumb:hover {
    background: #03a344;
  }

  .no-scrollbar::-webkit-scrollbar {
    background: transparent; /* Hide scrollbar for Chrome, Safari and Opera */
  }
  .no-scrollbar {
    background: transparent; /* IE and Edge */
    background: transparent; /* Firefox */
  }
}
